import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import facebookLogo from "../images/facebook.svg";
import twitterLogo from "../images/twitter.svg";
import linkedinLogo from "../images/linkedin.svg";
import mediumLogo from "../images/medium.svg";
import css from "./footer.module.css";

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "footer" } }) {
        frontmatter {
          list {
            text
            list {
              text
              link
              openInNewWindow
            }
          }
        }
      }
    }
  `);

  const footer = data.markdownRemark.frontmatter;

  return (
    <footer className={css.footer}>
      <Container>
        <div style={{ margin: "auto" }}>
          <img
            className="lazyload"
            style={{ height: 30, marginBottom: 50 }}
            data-src="/media/logo-white.svg"
            alt=""
          />
          <Grid container spacing={4}>
            {footer.list.map((li1, index) => (
              <Grid key={index} item xs={6} sm={4} md>
                <ul className={css.menu}>
                  <li className={css.header}>{li1.text}</li>
                  {li1.list.map((li2) => (
                    <li key={li2.text}>
                      <a
                        href={li2.link}
                        target={li2.openInNewWindow ? "_blank" : "_self"}
                        rel={li2.openInNewWindow ? "noopener noreferrer" : ""}
                      >
                        {li2.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}

            <Grid item xs={6} sm={4} md>
              <ul className={css.menu} style={{ flexGrow: 1 }}>
                <li className={css.header}>Get in touch</li>
                <li>
                  <a href="tel:+16503810077">+1-650-381-0077</a>
                </li>
                <li>
                  <a href="mailto:info@nanonets.com">info@nanonets.com</a>
                </li>
                <li style={{ maxWidth: 170 }}>
                  2261 Market Street #4010, San Francisco, CA 94114, USA
                </li>
                <li className={css.socialIcons}>
                  <a
                    href="https://www.facebook.com/nanonets"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="lazyload" data-src={facebookLogo} alt="" />
                  </a>
                  <a
                    href="https://twitter.com/nanonets_"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="lazyload" data-src={twitterLogo} alt="" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/nanonets/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="lazyload" data-src={linkedinLogo} alt="" />
                  </a>
                  <a
                    href="https://medium.com/nanonets"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="lazyload" data-src={mediumLogo} alt="" />
                  </a>
                </li>
              </ul>
            </Grid>
          </Grid>
          <div className={css.bottom}>
            <div style={{ marginRight: 30, padding: "7px 0" }}>
              {"© "}
              {new Date().getFullYear()}
              {" Nano Net Technologies Inc."}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
