/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { MuiThemeProvider, ThemeProvider } from "@material-ui/core";
import "lazysizes";
import Header from "./header";
import Footer from "./footer";
import theme from "../styles/theme";
import "./layout.css";
import "../utils/analytics";

const Layout = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <Header fixed />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  </MuiThemeProvider>
);

export default Layout;
