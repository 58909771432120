import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "@material-ui/core/Container";
import classes from "./header.module.css";

const Header = ({ fixed }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "header" } }) {
        frontmatter {
          list {
            text
            link
            openInNewWindow
            list {
              text
              link
              openInNewWindow
            }
          }
        }
      }
    }
  `);

  const header = data.markdownRemark.frontmatter.list;

  useEffect(() => {
    const dropdowns = document.querySelectorAll(`.${classes.navbar_nav} > li`);
    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener("mouseenter", (event) => {
        if ("ontouchstart" in window || navigator.maxTouchPoints) return;
        dropdowns.forEach((dropdown) => {
          dropdown.classList.remove(classes.open);
        });
        event.currentTarget.classList.add(classes.open);
      });

      dropdown.addEventListener("mouseleave", (event) => {
        if ("ontouchstart" in window || navigator.maxTouchPoints) return;
        event.currentTarget.classList.remove(classes.open);
      });

      dropdown.addEventListener("click", (event) => {
        event.stopPropagation();
        dropdowns.forEach((dropdown) => {
          if (dropdown !== event.currentTarget) {
            dropdown.classList.remove(classes.open);
          }
        });
        event.currentTarget.classList.toggle(classes.open);
      });
    });
    document.addEventListener("click", () => {
      dropdowns.forEach((dropdown) => {
        dropdown.classList.remove(classes.open);
      });
    });
  }, []);

  return (
    <header>
      {fixed && <div style={{ height: 67 }} />}
      <div />
      <div
        className={`${classes.navbar_wrapper} ${
          fixed ? classes.navbar_fixed : ""
        }`}
      >
        <Container maxWidth="xl">
          <div className={classes.navbar}>
            <div className={classes.navbar_header}>
              <a className={classes.navbar_brand} href="/">
                <img src="/media/logo2.png" className={classes.logo} alt="" />
              </a>
              <label
                htmlFor="navbar_nav_toggle"
                className={classes.navbar_toggle}
              >
                <span>☰</span>
              </label>
            </div>
            <div className={classes.navbar_nav_wrapper}>
              <input
                type="checkbox"
                id="navbar_nav_toggle"
                className={classes.navbar_nav_toggle}
              />
              <ul className={classes.navbar_nav}>
                {header.map((li1) => (
                  <li key={li1.text}>
                    {li1.link ? (
                      <a
                        href={li1.link}
                        target={li1.openInNewWindow ? "_blank" : "_self"}
                        rel={li1.openInNewWindow ? "noopener noreferrer" : ""}
                      >
                        {li1.text}
                      </a>
                    ) : (
                      <span className={classes.tab}>{li1.text}</span>
                    )}

                    {li1.list && (
                      <div className={classes.dropdown_wrapper}>
                        <ul className={classes.dropdown_menu}>
                          {li1.list.map((li2) => (
                            <li key={li2.text}>
                              <a
                                href={li2.link}
                                target={
                                  li2.openInNewWindow ? "_blank" : "_self"
                                }
                                rel={
                                  li2.openInNewWindow
                                    ? "noopener noreferrer"
                                    : ""
                                }
                              >
                                {li2.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}

                <li>
                  <a
                    id="signup"
                    href="https://app.nanonets.com/#/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.navbar_btn}
                  >
                    Get started for free
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
